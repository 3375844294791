<template>
  <div :class="{ 'cheer-gun': true, inversed: away }">
    <div
      v-for="item in items"
      :key="'item--' + item.id"
      :class="item.classes"
    >
      <div class="icon" />
    </div>

    <div class="wrapper">
      <div class="cheer-gun-button pulse-button pulse-button--light pulse-button--round">
        <i class="clap" />
      </div>
      <div class="counter">
        {{ counterLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import { WsClient, WsClientEvents } from 'smucio-ws-client';

export default {
  name: 'CheerGun',
  props: {
    wsClient: {
      type: WsClient || null,
      required: false,
      default: () => null,
    },
    away: {
      type: Boolean,
      default: false,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    items: [],
    counter: 0,
    lastAnimation: null,
  }),
  computed: {
    counterLabel() {
      if (this.counter < 1000) {
        return this.counter;
      }

      let resp = parseFloat(this.counter / 1000);
      if (this.counter < 100000) {
        resp = resp.toFixed(1);
      }

      return resp + 'K';
    },
  },
  watch: {
    wsClient: {
      immediate: true,
      handler(val) {
        console.log('OK', val);
        if (val !== null) {
          val.addEventListener(WsClientEvents.CHEER_RESULTS, ({ results }) => {
            console.log(results);
            if (typeof results[this.index] === 'number') {
              this.counter = results[this.index];
            }
          });

          val.addEventListener(WsClientEvents.CHEER, ({ data }) => {
            if (data.index === this.index) {
              this.counter = data.value;
              this.trigger();
            }
          });
        }
      }
    },
  },
  methods: {
    trigger() {
      const now = (new Date()).getTime();
      if (this.lastAnimation + 50 >= now) {
        return;
      }

      this.lastAnimation = now;
      const itemId = this.generateItemId();
      const randomX = Math.floor(Math.random() * 3 + 1);
      const randomY = Math.floor(Math.random() * 3 + 1);
      const randomXDelay = Math.floor(Math.random() * 3 + 1);
      const randomYDelay = Math.floor(Math.random() * 3 + 1);

      this.items.push({
        id: itemId,
        classes: [
          'item',
          'shake-y-' + randomY,
          'shake-x-' + randomX,
          'shake-x-delay-' + randomXDelay,
          'shake-y-delay-' + randomYDelay,
        ]
      });
      setTimeout(() => {
        this.items = this.items.filter(e => e.id !== itemId);
      }, 50000);
    },
    generateItemId() {
      return (Math.random() + 1).toString(36).substring(7);
    },
  }
}
</script>
