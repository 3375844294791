<template>
  <div class="cheering-screen">
    <div class="cheering-window">
      <div class="textual">
        <div class="title">
          Hit the like and change the team
        </div>
        <!-- <div class="cheer-text">
          Go rams
        </div> -->
      </div>

      <div class="cheer-container">
        <img :src="homeLogo" class="team-logo" />

        <div class="cheer-status">
          <div class="cheer-counters">
            <cheer-gun
              :ws-client="wsClient"
              :style="{'--color': homeColor}"
              :index="0"
            />
            <cheer-gun
              :ws-client="wsClient"
              :style="{'--color': awayColor}"
              :index="1"
              away
            />
          </div>
          <div class="cheer-bar-container">
            <img
              :class="barOverlayClasses"
              src="./assets/img/cheer-smudge.svg"
              :style="{left: calculateCheerBarPercentage.home}"
            />
            <div class="cheer-bar">
              <div class="home" :style="{width: calculateCheerBarPercentage.home, '--color': homeColor}" />
              <div class="away" :style="{width: calculateCheerBarPercentage.away, '--color': awayColor}" />
            </div>
          </div>
        </div>

        <img :src="awayLogo" class="team-logo" />
      </div>
    </div>
  </div>
</template>

<script>
import CheerGun from '@/components/CheerGun';
import { WsClient, WsClientEvents } from 'smucio-ws-client';
import { StreamData } from 'smucio-stream-entities'

export default {
  name: 'CheeringScreen',
  components: { CheerGun },
  data() {
    return {
      now: null,
      wsClient: null,
      stream: null,
      cheers: [],
      barOverlayLastMoveTime: null,
      barOverlayIsInversed: false,
    };
  },
  computed: {
    apikey() {
      return this.$parent.smucapikey;
    },
    homeLogo() {
      return this.stream ? this.stream.team_home_logo.url : '';
    },
    homeColor() {
      return this.stream ? this.stream.team_home_color : '';
    },
    awayLogo() {
      return this.stream ? this.stream.team_away_logo.url : '';
    },
    awayColor() {
      return this.stream ? this.stream.team_away_color : '';
    },

    calculateCheerBarPercentage() {
      if (this.cheers.length) {
        return {
          home: '50%',
          away: '50%',
        };
      }

      const total = this.cheers[0] + this.cheers[1];
      const home = (this.cheers[0] / total) * 100;
      const away = (this.cheers[1] / total) * 100;

      return {
        home: `${home}%`,
        away: `${away}%`,
      };
    },
    barOverlayClasses() {
      return {
        'overlay': true,
        'inversed': this.barOverlayIsInversed,
        'visible': this.barOverlayVisible,
      };
    },
    barOverlayVisible() {
      return this.barOverlayLastMoveTime + 5000 > this.now;
    },
  },
  watch: {
    'calculateCheerBarPercentage.home'(val, oldVal) {
      this.barOverlayIsInversed = val < oldVal;
      this.barOverlayLastMoveTime = (new Date()).getTime();
    },
  },
  created() {
    this.fetchData();
    setInterval(() => this.now = (new Date()).getTime(), 1000);
  },
  methods: {
    fetchData() {
      window.fetch(`${process.env.VUE_APP_SMUC_STREAMS_URL}${this.apikey}.smc?${(new Date()).getTime()}`)
        .then(response => response.json())
        .then(data => {
        this.initWsConnection();
        this.stream = new StreamData(data);
        this.stream.apikey = this.apikey;
      })
      .catch(e => {
        console.error('[SmucPlayer] Failed to retrieve Stream data.', e);
      });
    },
    initWsConnection() {
      this.wsClient = new WsClient({
        wsUrl: process.env.VUE_APP_CHAT_WS_URL,
        apiKey: this.apikey,
        serverPublicKey: process.env.VUE_APP_WS_MESSAGE_SERVER_PUBLIC_KEY,
        clientPrivateKey: process.env.VUE_APP_WS_MESSAGE_CLIENT_PRIVATE_KEY,
      });
      this.wsClient.addEventListener(WsClientEvents.CHEER_RESULTS, results => this.cheers = results.results);
      this.wsClient.addEventListener(WsClientEvents.MATCH_INFO, info => {
        Object.keys(info).forEach(key => {
          this.stream[key] = info[key];
        });
      });
      this.wsClient.addEventListener(WsClientEvents.CHEER, msg => {
        const index = msg.data.index;
        const value = msg.data.value;
        this.cheers[index] = value;


      });
    },
  },
}
</script>
