export default {
  computed: {
    isFirefox() {
      return /firefox\/[0-9]{1,3}\.[0-9]{1,3}/i.test(navigator.userAgent);
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    isIos() {
      return !!navigator.userAgent.match(/iPad/i) || !!navigator.userAgent.match(/iPhone/i);
    },
    isIpadOS() {
      return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
    },
    isIpadPro() {
      return /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
    },
    isWebKit() {
      return !!navigator.userAgent.match(/WebKit/i);
    },
    isIosSafari() {
      return this.isIos && this.isWebKit && !navigator.userAgent.match(/CriOS/i);
    },
    browserVisualViewportSupported() {
      return Object.prototype.toString.call(window.visualViewport) === '[object VisualViewport]';
    },
  }
}
